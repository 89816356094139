import React, { FC } from "react";
import { LpRpo01PageLayout } from "~/molecules/lp/rpo/01/LpRpo01PageLayout";
import { CallToActionButton } from "~/atoms/CallToActionButton";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { useLocation } from "@reach/router";

type LpRpo01ThanksType = {};

const LpRpo01Thanks: FC<LpRpo01ThanksType> = () => {
  const location = useLocation();

  return (
    <>
      <LpRpo01PageLayout title="資料請求・お問い合わせ" noindex={true} hideHeaderDownloadButton={true}>
        <div className="thanks">
          <div className="title">資料請求・お問い合わせ</div>
          <div className="subTitle">お問い合わせありがとうございます</div>
          <div className="body">
            この度は、Refcome にご興味を持っていただき、ありがとうございます。
            <br className="spBr" />
            おって、ご入力いただいたアドレス宛に、担当の方からご連絡を差し上げます。
            <br />
            <br />
            なお、お申し込み受付のメールをお送りしております。
            <br className="spBr" />
            30分以内にメールが届かない場合は、メールアドレスの記入ミスの可能性がございます。
            <br className="spBr" />
            <br className="spBr" />
            お手数ですが、再度お問い合わせをお願いいたします。
            <br />
            <br />
            引き続き、Refcomeをよろしくお願いいたします。
            <br />
          </div>
          <div className="button">
            <CallToActionButton to={`/lp/rpo/01${location.search}`} size={`250px`} backgroundColor={colors.creamGray}>
              トップページへ戻る
            </CallToActionButton>
          </div>
        </div>
      </LpRpo01PageLayout>

      <style jsx>{`
        .thanks {
          margin-top: 92px;
          align-items: center;
          background: ${colors.bgGrayGradient};
          display: flex;
          flex-direction: column;
          padding: 50px 20px;
        }
        .title {
          font-size: 32px;
          font-weight: 500;
        }
        .subTitle {
          margin-top: 30px;
          font-size: 24px;
          font-weight: 500;
        }
        .body {
          font-size: 15px;
          line-height: 1.8;
          text-align: center;
          margin-top: 30px;
        }
        .button {
          margin-top: 30px;
        }
        @media (${breakPoints.sp}) {
          .thanks {
            margin-top: 72px;
          }
          .title {
            font-size: 24px;
            font-weight: 500;
          }
          .subTitle {
            margin-top: 30px;
            font-size: 18px;
            font-weight: 500;
          }
          .body {
            font-size: 14px;
          }
          .spBr {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default LpRpo01Thanks;
